import React from "react"
import Layout from "../components/layout"
import { Link, graphql, useStaticQuery } from "gatsby"

const QigongPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(chikung)/" } }

        sort: { order: ASC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id
            }
            fields {
              slug
            }
            html
            excerpt(pruneLength: 280)
          }
        }
      }
    }
  `)

  // console.log(data)

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h1>Chi Kung (Qi Gong)</h1>
            <div className="container">
              {data.allMarkdownRemark.edges.map(edge => {
                return (
                  <div className="post" key={edge.node.id}>
                    <Link to={`/qigong/${edge.node.fields.slug}`}>
                      <h2>{edge.node.frontmatter.title}</h2>
                      <p>{edge.node.excerpt}</p>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  )
}

export default QigongPage
